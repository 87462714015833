<template>
  <div class="login">
    <div class="login-box">
      <div class="title">ADMIN MANAGMENT SYSTEM</div>
      <a-input
        type="text"
        size="large"
        v-model="form.email"
        placeholder="email"
      />
      <VueHcaptcha
        ref="invisibleHcaptcha"
        sitekey="9d63c817-4806-42a0-8368-539b7b98cb03"
        size="invisible"
        @verify="onVerify"
        @error="onError"
        @closed="onClose"
      />
      <a-input
        type="password"
        size="large"
        v-model="form.password"
        placeholder="password"
      />
      <a-button
        block
        type="primary"
        :loading="loading"
        size="large"
        @click="onSubmitClick"
      >
        LOGIN
      </a-button>
    </div>
  </div>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import md5 from 'md5';

export default {
  name: 'Login',
  components: { VueHcaptcha },
  data() {
    return {
      loading: false,
      form: {
        email: '',
        password: '',
      },
    };
  },
  methods: {
    async onVerify(token) {
      const { email, password } = this.form;
      const { err, res } = await this.$api.admin.login({
        email,
        password: md5(password),
        token,
      });

      if (!err) {
        // 保存用户信息
        this.$store.commit('user/setState', {
          userInfo: res.result.user,
          token: res.result.token,
        });
        this.$message.success(res.msg);
        // 跳转首页
        this.$router.push('/console');
      }
      this.loading = false;
    },
    onError(err) {
      console.error(err);
      this.$message.error('验证码出错');
    },
    onClose() {
      this.loading = false;
    },
    onSubmitClick() {
      this.loading = true;
      this.$refs.invisibleHcaptcha.execute();
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(https://piapp.oss-us-west-1.aliyuncs.com/images/bg_login.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .login-box {
    width: 500px;
    height: 360px;
    padding: 40px;
    background: rgba(#eee, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 8px;
    .title {
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 30px;
    }
    .ant-input {
      margin-bottom: 20px;
    }
  }
}
</style>
